.faq {
  .question-container {
    margin-bottom: 20px;
    border-bottom: #e6e6e6 1px solid;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    margin: 2px;
    justify-items: center;

    .question {
      color: #333333;
      flex-wrap: wrap;
      font-family: Muli;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 42px;
    }
  }

  .answer-container {
    width: 100%;
    padding: 20px;

    .answer {
      color: #333333;
      font-family: "PT Sans";
      font-size: 16px;
      letter-spacing: 0.1px;
    }
  }
}
