.App {
  //text-align: center;
  font-family: Muli !important;
  overflow-x: visible;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.button {
  height: 48px;
  width: 100%;
  border-radius: 4px;
  font-weight: 900;
  cursor: pointer;

  &.submit {
    background-color: #35cc62;
    color: #ffffff;
  }

  &.basic {
    background-color: #ffffff;
    color: #5b6576;
    border: 2px solid #b0b9c9 !important;
  }

  &.submit-save {
    background-color: #5692ff;
    color: #ffffff;
  }

  &.disabled {
    background-color: #f1f3f7;
    color: #b0b9c9;
  }

  .text {
    height: 14px;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 14px;
    text-align: center;
  }
}

.page-title {
  height: 42px;
  color: #27395a;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
}

.page-content {
  background-color: #ffffff;
  box-shadow: -6px 5px 10px 0 rgba(196, 192, 192, 0.09);
  padding: 25px;
  margin-bottom: 30px;

  .page-content-grey {
    background-color: #f9f9f9;
    box-shadow: -6px 5px 10px 0 rgba(179, 179, 179, 0.09);
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 4px;
  }

  .title {
    height: 29px;
    color: #333333;
    font-size: 19px;
    font-weight: 600;
    line-height: 29px;
  }

  .form-title {
    height: 29px;
    color: #35cc62;
    font-size: 19px;
    font-weight: 600;
    line-height: 29px;
    padding-bottom: 10px;
  }
}

.select-box {
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
}

.select-box-label {
  height: 18px;
  color: #333333;
  font-size: 14px;
  letter-spacing: 0.1px;
  vertical-align: center;
}

.row {
  margin-bottom: 15px;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  visibility: hidden !important;
}

.button {
  border: none;
}

.table-layout {
  //margin: 30px;

  .header-container {
    padding: 0px !important;

    .title {
      height: 42px;
      padding: 10px;
      color: #27395a;
      font-family: Muli;
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 0px;
    }

    .sub-title {
      height: 17px;
      padding: 20px;
      color: #333333;
      font-family: "Muli";
      //font-family: ".SF NS Display";
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 17px;
      text-align: right;
    }
  }

  .body-container {
    background-color: #fff;

    .list-container {
      padding: 20px;
      margin-bottom: 0px;

      .list-header {
        height: 29px;
        color: #333333;
        font-family: Muli;
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 29px;
      }

      .list-dropdown {
        height: 32px;
        color: #b0b9c9;
        font-family: "PT Sans";
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 19px;
        text-align-last: center;
      }
    }

    .data-grid-container {
      padding: 20px;
    }
  }

  .row {
    margin: 0px;
  }
}

button:focus {
  outline: none !important;
}

a,
button {
  cursor: pointer;
}
