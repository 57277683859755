.admin-verified {
  height: 57px;
  background-color: rgba(53, 204, 98, 0.13);
  border-radius: 10px;

  width: 226px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-selection {
  height: 20px;
  display: flex;
  align-items: center;

  input[type="radio"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #99a4b4;
    border-radius: 50%;
    outline: none;
    //box-shadow: 0 0 5px 0px #99a4b4 inset;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: #35cc62;
  }

  .radio-text {
    overflow: hidden;
    color: #333333;
    font-family: Muli;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: normal !important;
    margin-left: 10px;
    margin-bottom: 0px;
  }
}

.notes-header-text {
  height: 29px;
  color: #9BA3B2;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 29px;
}

.notes-title-text {
  color: #333333;
  font-family: Muli;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  // width: 8em;
}

.notes-edit-button {
  height: 32px;
  border-radius: 2px;
  background-color: rgba(53, 204, 98, 0.14);
  color: #35cc62;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}

.last-edit-text {
  height: 32px;
  width: 181px;
  color: #a3abb9;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}

.view-full-edit-history {
  margin-left: 10px;
  height: 32px;
  width: 119px;
  color: #35cc62;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
}

.note-history {
  padding: 10px;
  border-top: 2px solid rgba(151, 151, 151, 0.24);
}

.history-note-text {
  color: #9ba3b2;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
}

.history-note-text-empty {
  font-style: italic;
  color: #99a4b4;
  font-family: Muli;
  font-size: 12px;
  letter-spacing: 0;
  margin-bottom: 10px !important;
}

.history-note-time {
  margin-top: 10px;
  padding: 10px;
  color: #a3abb9;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
}


.notes-container {
  margin: 2em 0;
}

.signature-container {
  background-color: #FEFEFE;
  border-radius: 11px;
  border: 1px solid #E6EBF6;
  width: 462px;
  padding: 2em 0;
}

.cont-info-history {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.5em 0;
  row-gap: 0.5em;
}

.arrow-icon {
  height: 22px;
  width: 22px;
  color: #35CC62;
  font-family: Feather-Icons;
  font-size: 22px;
  letter-spacing: 0;
  line-height: NaNpx;
}

.flex-history {
  display: flex;
  gap: 0.5em;
  align-items: center;
  width: max-content;
  cursor: pointer;
}

.flex-gap {
  display: flex;
  gap: 0.5em;
}

@media (max-width: 600px) {


  .signature-container {
    width: auto;
  }

  .cont-info-history {
    grid-template-columns: 1fr;
  }

  .notes-header-text {
    height: auto;
  }
}