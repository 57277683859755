.signature-container {

  .sigCanvas {
    touch-action: none;
    //height: 123px;
    width: 623px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .signature-clear {
    margin-top: 15px;
    height: 26px;
    width: 127px;
    background-color: #5692FF;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 14px;
    text-align: center;
  }

  .signature-close {
    margin-top: 15px;
    margin-left: 15px;
    height: 26px;
    width: 127px;
    background-color: #35CC62;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 14px;
    text-align: center;
  }
}

.signature-view-container {

  .signature-view {
    height: 123px;
    width: 623px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    padding: 5px 5px 5px 5px;
  }

  .signature-edit {
    margin-top: 15px;
    margin-left: 15px;
    height: 26px;
    width: 127px;
    background-color: #35CC62;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 14px;
    text-align: center;
  }

}

@media only screen and (max-width: 768px) {
  .signature-container {
    .sigCanvas {
      width: 275px;
    }
  }
  .signature-view-container {
    .signature-view {
      width: 275px;
    }
  }
}