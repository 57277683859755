$mobile-width: 576px;
.ReactTable {
  width: 100%;
  min-height: 33px;
  border: none !important;

  .rt-table {
    overflow-x: scroll !important;
  }
  .rt-tr-group:hover {
    background-color: rgba(53, 204, 98, 0.08) !important;

    .rt-tr {
      .rt-td {
        background-color: inherit !important;
      }
    }
  }

  .rt-thead.-header {
    box-shadow: none;
  }

  .rt-noData {
    //top: 69% !important;
    padding: 10px !important;
    width: 100%;
    margin: 0px !important;
    font-size: 18px !important;
    text-align: center;
    font-family: Muli;
    z-index: 0;
    height: 40px !important;
  }

  .rt-thead .rt-th,
  .rt-tbody .rt-td {
    border: none !important;
    padding: 0px 7px;
  }
}

.cell {
  color: #333333;
  font-size: 14px;
  line-height: 33px;
  height: 33px;
  margin: 0px;

  .basic-cell {
    text-align: left;
    white-space: nowrap;
  }

  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .overflow-report {
    word-break: break-all !important;
  }

  .select-cell {
    text-align: center;

    .checkbox {
      box-sizing: border-box;
      height: 20px;
      width: 20px;
      border: 2px solid #35cc62;
      border-radius: 4px;
    }
  }

  &.action-cell {
    text-align: center;
    cursor: pointer;
  }

  &.link-cell {
    text-align: left;
    white-space: nowrap;

    .link-text {
      color: #35cc62 !important;
      text-decoration: underline !important;
      cursor: pointer;
    }

    .link-text-disabled {
      color: rgb(176, 185, 201);
      font-size: 12px;
      font-style: italic;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
}

.custom-header {
  color: #5b6576;
  font-size: 16px;
  font-weight: bold;
  line-height: 35px;
  text-align: left;
  height: 37px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-report-header {
  text-align: left;
  height: 26px;
  color: #838d9e;
  font-family: Muli;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-cell {
  width: 100%;
  border-radius: 5%;
  font-size: 10px;
  font-weight: bold;

  .completed-status {
    background-color: rgba(53, 204, 98, 0.3);
    color: #35cc62;
    height: 24px;
    padding: 3px;
    margin-top: 3px;
    border-radius: 5px;
  }

  .abandoned-status {
    background-color: #b0b9c9;
    color: #5b6576;
    height: 24px;
    padding: 3px;
    margin-top: 3px;
    border-radius: 5px;
  }

  .mobile-device-status {
    background-color: transparent;
    color: #5b6576;
    height: 24px;
    padding: 3px;
    margin-top: 3px;
    border-radius: 5px;
  }

  .progress-status {
    background-color: #d05317ad;
    color: #d05317;
    height: 24px;
    padding: 3px;
    margin-top: 3px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .record-count {
    width: auto !important;
  }
  .page-number-container {
    padding: 0px !important;
  }
}

.pagination {
  .row {
    margin-bottom: 0px;
  }

  .record-count {
    padding: 13px;

    .record-count-text-container {
      width: 90px;
    }

    .record-count-text {
      height: 26px;
      width: 100%;
      margin: 5px;
      font-size: 14px;
      color: #333333;
      font-family: Muli;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
    }

    .record-count-list {
      box-sizing: border-box;
      height: 26px;
      width: 76px;
      margin-top: 5px;
      margin-bottom: 5px;
      border: 2px solid #e6eaf1;
      border-radius: 7px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .page-number {
    box-sizing: border-box;
    height: 48px;
    width: 48px;
    border: 2px solid #e6eaf1;
    border-radius: 4px;
    margin: 5px;

    :hover {
      cursor: pointer;
    }

    &.active {
      background-color: #35cc62;
      color: #ffffff;
    }
  }

  .page-arrow {
    height: 20px;
    width: 20px;
    color: #5b6576;
    font-size: 20px;

    :hover {
      cursor: pointer;
    }
  }
}
.pagination-align {
  text-align: right;
}
@media (max-width: $mobile-width) {
  .pagination-align {
    text-align: center;
  }
}
