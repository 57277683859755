.drop_area drop_zone {
  width: 100%;
}



.upload-wrapper{
  padding: 10px;
  margin: 10px;
  min-height: 66px;
  border-radius: 8px;
  align-content: center;

  div {
    align-content: center;
  }

}

.file-upload {
  padding: 10px;
  //margin: 10px;
  background-color: rgba(86,146,255,0.16);
  min-height: 66px;
  border-radius: 8px;
  align-content: center;

  .img {
    margin-right: 25px;
  }

  .text {
    height: 18px;
    width: 196px;
    color: #5692FF;
    font-family: "PT Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
}

.missing-clients {
  padding: 10px;
  margin: 10px;
  background-color: #FA7B6F;
  min-height: 66px;
  border-radius: 8px;
  align-content: center;

  .img {
    margin-right: 25px;
  }

  .text {
    height: 18px;
    width: 196px;
    color: #FAFBFC;
    font-family: "PT Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
}

.import-none {
  padding: 10px;
  margin: 10px;
  background-color: rgba(53,204,98,0.25);
  min-height: 66px;
  border-radius: 8px;
  align-content: center;

  .img {
    margin-right: 25px;
  }

  .text {
    height: 18px;
    width: 196px;
    color: #03771d;
    font-family: "PT Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
}


.missing-services {
  padding: 10px;
  margin: 10px;
  background-color: rgba(255, 199, 52, 0.13);
  min-height: 66px;
  border-radius: 8px;
  align-content: center;

  .img {
    margin-right: 25px;
  }

  .text {
    height: 18px;
    width: 196px;
    color: #5B6576;
    font-family: "PT Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
}





// refactor below

.client-details {
  color: #27395a;
  font-family: Muli;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 42px;
}

.btn_edit {
  border-radius: 4px;
  background-color: #35cc62;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
  width: auto;
  padding-left: 24px;
  padding-right: 24px;
  margin-right: 10px;
}

.btn_edit_black {
  border-radius: 4px;
  background-color: #27395a;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
  width: auto;
  padding-left: 24px;
  padding-right: 24px;
  margin-right: 10px;
}

.btn_delete_black {
  border-radius: 4px;
  background-color: #27395a;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
  width: auto;
  padding-left: 24px;
  padding-right: 24px;
  margin-right: 10px;
}

.btn_delete {
  box-sizing: border-box;
  border: 2px solid #b0b9c9;
  border-radius: 4px;
  color: #5b6576;
  font-family: Muli;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 14px;
  text-align: center;
  width: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.banner {
  width: 100%;
}

.user_image {
  box-sizing: border-box;
  height: 105px;
  width: 96px;
  border: 5px solid #ffffff;
  background-color: #fafbfc;
  margin: -48px 0 0 0;
}

.active_tabs {
  border-bottom: #35cc62;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.inactive_tabs {
  padding: 0px;
}

.tab_text_style {
  color: #333333;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  cursor: pointer;
}

// basic information related styles
.header_text {
  color: #99a4b4;
  font-family: Muli;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 29px;
}

.change-password {
  color: #35cc62;
  font-family: Muli;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 900;
  background-color: rgba(53, 204, 98, 0.08);
  height: 48px;
  text-align: center;
  padding: 15px;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
}

.title_text {
  color: #333333;
  font-family: Muli;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 29px;
}

.title_text_profile_link {
  color: #2765b7;
  font-family: Muli;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 29px;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

// services list related styles
.btn-new-service {
  box-sizing: border-box;
  height: 70px;
  width: 100%;
  border: 2px dashed #35cc62;
  border-radius: 6px;
  display: table;
}

.add-new-service {
  display: table-cell;
  vertical-align: middle;
  color: #35cc62;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
}

.credential-item {
  border-radius: 15.5px;
  background-color: #35cc62;
  color: white;
  height: 35px;
  width: 100%;
  padding: 5px;

  &.active {
    background-color: #1aaec5;
  }
}

.staff-allocation {
  .dual-list-box {
    margin-right: 0px !important;
    margin-left: 0px !important;

    .staff-allocation-search {
      margin-left: -10px;
      height: 32px;
    }

    .list-box {
      overflow-y: auto;
      min-height: 400px;
      max-height: 400px;
      margin-top: 5px;

      border: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
    }

    .list-ite {
      border-radius: 15.5px;
      background-color: #35cc62;
      color: white;
      margin: 15px;

      min-height: 31px;

      justify-content: stretch;
      justify-items: center;

      &.active {
        background-color: #1aaec5;
      }
      .child-item {
        background-color: "red";
        word-wrap: break-word;
        margin: 4px;
      }
    }

    .list-ite:hover {
      cursor: pointer;
    }

    .box-header {
      color: #b0b9c9;
      font-family: "PT Sans";
      font-size: 14px;
    }
  }

  .list-box-arrow {
    //border: 0.5px solid #98a3bd;
    margin-bottom: 2px;
  }

  .list-box-arrow:hover {
    cursor: pointer;
  }
}
