.navigation-title {
  color: #5B6576 !important;
  font-size: 22px !important;
  font-weight: 900 !important;
  line-height: 15px !important;
}

.nav-bar-item {
  height: 19px;
  color: #35cc62;
  font-size: 15px;
  font-weight: 700;
  line-height: 19px;
  margin-left: 15px !important;
}

.nav-link {
  cursor: pointer;
}

.mt-2, .my-2 {
  margin-top: 2rem !important
}
