//.table-layout {
//
//  margin: 30px;
//
//  .header-container {
//    padding: 20px;
//
//    .title {
//      height: 42px;
//      padding: 10px;
//      color: #27395A;
//      font-family: Muli;
//      font-size: 28px;
//      font-weight: 600;
//      letter-spacing: 0;
//      line-height: 50px;
//    }
//
//    .sub-title {
//      height: 17px;
//      padding: 20px;
//      color: #333333;
//      font-family: "Muli";
//      //font-family: ".SF NS Display";
//      font-size: 14px;
//      letter-spacing: 0.1px;
//      line-height: 17px;
//      text-align: right;
//    }
//
//  }
//
//  .body-container {
//    background-color: #FFF;
//
//    .list-container {
//      padding: 20px;
//      margin-bottom: 0px;
//
//      .list-header {
//        height: 29px;
//        color: #333333;
//        font-family: Muli;
//        font-size: 19px;
//        font-weight: 600;
//        letter-spacing: 0;
//        line-height: 29px;
//      }
//
//      .list-dropdown {
//        height: 29px;
//        color: #B0B9C9;
//        font-family: "PT Sans";
//        font-size: 14px;
//        letter-spacing: 0.1px;
//        line-height: 19px;
//        text-align-last: center;
//      }
//
//    }
//
//    .data-grid-container {
//      padding: 20px;
//    }
//
//  }
//
//
//
//}


.password-container {
  .password-text {
    margin-bottom: 0px !important;
  }
}
