.loader {
    position: fixed; /* Sit on top of the page content */
    display: block;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(206, 206, 206, 0.35); /* Black background with opacity */
    z-index: 9999999999; /* Specify a stack order in case you're using a different order for other elements */
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    /*color: white;*/
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
