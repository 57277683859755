.footer {
  width: 100%;
  position: fixed;
  bottom: 0;

  .title {
    height: 25px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 900;
    line-height: 20px;
    margin-top: 10px;
  }

  hr {
    box-sizing: border-box;
    height: 2px;
    border: 1px solid #979797;
    opacity: 0.31;
    padding-left: 5px;
    padding-right: 5px;
  }

  .rights {
    height: 30px;
    color: #A4A4A4;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 10px;
  }

}
