.validation-container {

  margin: 20px 20px 20px 0px;

  .text-description {
    color: #333333;
    font-family: Muli;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 10px;
  }

  .text-error {
    color: #FF584E;
    font-family: Muli;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 10px;
  }

}




