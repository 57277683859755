$mobile-width: 576px;
.Table {
  width: 100%;
  min-height: 33px;
  text-overflow: ellipsis;
  overflow: hidden;

  .rt-tbody .rt-td {
    border: none;
    padding: 0px 7px;
  }
  .custom-header {
    color: #5b6576;
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: bold;
    line-height: 35px;
    text-align: left;
    height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  }

  .cell {
    color: #333333;
    font-size: 14px;
    line-height: 33px;
    height: 33px;
    cursor: default;
    margin: 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);

    .basic-cell {
      text-align: left;
      white-space: nowrap;
    }

    .overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .overflow-report {
      word-break: break-all !important;
    }

    .select-cell {
      text-align: center;

      .checkbox {
        box-sizing: border-box;
        height: 20px;
        width: 20px;
        border: 2px solid #35cc62;
        border-radius: 4px;
      }
    }

    &.action-cell {
      text-align: center;
      cursor: pointer;
    }

    &.link-cell {
      text-align: left;
      white-space: nowrap;

      .link-text {
        color: #35cc62 !important;
        text-decoration: underline !important;
        cursor: pointer;
      }

      .link-text-disabled {
        color: rgb(176, 185, 201);
        font-size: 12px;
        font-style: italic;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }
  .rt-tr-group:hover {
    background-color: rgba(53, 204, 98, 0.08) !important;

    .rt-tr {
      .rt-td {
        background-color: inherit !important;
      }
    }
  }
}
.pagination-align {
  text-align: right;
}
@media (max-width: $mobile-width) {
  .pagination-align {
    text-align: center;
  }
}
