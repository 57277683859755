.dual-list-box {
  margin-right: 0px !important;
  margin-left: 0px !important;

  .list-box {
    overflow-y: auto;
    max-height: 270px;
    margin-top: 5px;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .list-item {
    border-radius: 15.5px;
    background-color: #35CC62;
    color: white;
    margin: 15px;
    height: 31px;

    &.active {
      background-color: #1aaec5;
    }
  }

  .list-item:hover {
    cursor: pointer;
  }

  .box-header {
    color: #B0B9C9;
    font-family: "PT Sans";
    font-size: 14px;
  }
}

.list-box-arrow {
  //border: 0.5px solid #98a3bd;
  margin-bottom: 2px;
}

.list-box-arrow:hover {
  cursor: pointer;
}