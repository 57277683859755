body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table, th, td {
  border: 2px solid #969696;
}

th{
  background-color: #DCDCDC;
}

td,th{
  text-align: left !important;
  color: #333333;
  /* font-weight: bold; */
}

table{
  width: 100%;
}

tr:nth-child(2){
  /* background: #FF5D5D !important */
}

tr:nth-child(3){
  /* background: #54BFFF !important */
}

/* tr:nth-child(5){
 font-weight: bold;
 text-align: center !important;
} */
