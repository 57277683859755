.app-container{
  // margin-left: 3px !important;
  margin-top: 70px !important;
  //margin-right: 35px !important;
  padding: 15px 30px 30px !important;
}

.content-wrapper{
  background-color: #FAFBFC !important;
}
