$mobile-width: 576px;
.report-body {
  background-color: #fafbfc;
  padding: 20px;
}

.no-border {
  border: 0px !important;
}

.only-border {
  border: 2px solid #b0b9c9 !important;
}

.report-generation {
  color: #333333;
  font-size: 15px;
  line-height: 29px;

  .status {
    font-size: 14px;
  }
}

.filters {
  box-sizing: border-box;
  border: 0px solid #e0e1e2;
  padding: 15px;

  .title {
    font-family: Muli;
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    line-height: 29px;
  }
}

.grey-area-group {
  border-radius: 4px;
  background-color: #fafbfc;
  padding: 30px 60px;
  margin-bottom: 20px;
  width: 100%;
}

.filter-text {
  color: #5b6576;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 14px;
  margin-right: 10px;
}

.filter-text:hover {
  cursor: pointer;
}

.vertical-line {
  border-left: 1px dotted #e0e1e2;
  height: 100%;
}

.excel-view {
  border: 1px dotted #e0e1e2;
  padding: 15px;
}

.box {
  box-shadow: none !important;
}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    margin-top: 0px;
  }
}

#excel-viewer {
  padding: 20px;
}

.report-stats {
  background-color: rgba(86, 146, 255, 0.11);
  border-radius: 4px;
  height: 42px;
  width: 100%;
  margin: 0px;
  margin-bottom: 20px !important;

  .label {
    text-align: center;
    height: 20px;
    color: #5692ff;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 10px;
  }
}
@media (max-width: $mobile-width) {
  .report-stats {
    height: auto;

    .label {
      height: auto;
    }
  }
  .report-details-header {
    height: auto !important;
  }
  .report-body {
    padding: 0;
  }
  .report-type-selector {
    margin-left: -7.5px !important;
    padding: 0 10px;
  }
  .btn-section {
    margin-top: 10px;
  }
  .input-box .box {
    height: 48px !important;
  }
}
.report-type-selector {
  margin-left: 20px;
}
.report-details-header {
  height: 40px;
  //background-color: rgba(176, 185, 201, 0.08);
  margin-top: 30px;
  border-radius: 4px;
  border-bottom: rgba(176, 185, 201, 0.3) 1px solid;

  .title-value-container {
    padding: 10px;
    .title-text {
      height: 20px;
      color: #35cc62;
      font-family: Muli;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

    .value-text {
      height: 20px;
      color: #27395a;
      font-family: Muli;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}
@media (max-width: 1232px) and ( min-width:768px) {
  .label {
    height: 34px !important;
  }
  .action-button {
    top: 52px !important;
  }
}
