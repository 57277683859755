.profile {
  margin-top: 50px;

  .role {
    border-radius: 15.5px;
    background-color: #FFC734;

    .text {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
    }
  }

  .personal-info {
    color: #333333;
    font-size: 16px;
    line-height: 26px;
  }

  .key {
    color: #99A4B4;
    font-size: 14px;
    line-height: 29px;
  }

  .value {
    color: #333333;
    font-size: 15px;
    line-height: 29px;
  }

  svg {
    height: 200px;
    width: 240px;
    margin: 10px;
    z-index: 1;
    position:relative;
  }
  path {
    stroke: #FFF;
    stroke-width: 2.5px;
    fill: #ECECEC;
  }

  .initials {
    fill: #9C9C9C;
    font: bold 30px Muli;
  }

}