
.table-layout {

  margin: 30px;

  .header-container {
    padding: 20px;

    .title {
      height: 42px;
      padding: 10px;
      color: #27395A;
      font-family: Muli;
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 0px;
    }

    .sub-title {
      height: 17px;
      padding: 20px;
      color: #333333;
      font-family: "Muli";
      //font-family: ".SF NS Display";
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 17px;
      text-align: right;
    }

  }

  .body-container {
    background-color: #FFF;

    .tabbed-row {
      padding: 20px 0px 0px 0px;

      img {
        margin: 0px 10px 5px 0px;
      }

      .inactive_tabs {
        background-color: transparent;
        color: #5B6576;
      }

      .completed-active-tab {
        //background-color: rgba(53, 204, 98, 0.3);
        //border-radius: 5px;
        color: #35CC62;
        border-bottom: rgba(53, 204, 98, 0.3);
        border-bottom-width: 2px;
        border-bottom-style: solid;
      }

      .inprogress-active-tab {
        //background-color: #d05317ad;
        //border-radius: 5px;
        color: #d05317;
        border-bottom: #d05317ad;
        border-bottom-width: 2px;
        border-bottom-style: solid;
      }

      .abandoned-active-tab {
        //background-color: #B0B9C9;
        //border-radius: 5px;
        color: #5B6576;
        border-bottom: #B0B9C9;
        border-bottom-width: 2px;
        border-bottom-style: solid;
      }

    }

    .data-grid-container {
      padding: 20px;
    }

  }

  .list-container {

    padding: 20px;
    margin-bottom: 0px;

    .list-header {
      height: 32px;
      color: #333333;
      font-family: Muli;
      font-size: 19px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 29px;
    }

    .list-dropdown {
      height: 32px;
      color: #B0B9C9;
      font-family: "PT Sans";
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 19px;
      text-align-last: center;
    }

    .searchable-dropdown-container {
      padding: 0px;
      height: 32px;

      .input-field {
        height: 32px;
      }

      .action-button {
        position: absolute;
        top: 37px;
        right: 15px;
      }

      .drop-down-list {
        top: 65px;
        width: 93%;
      }

    }

    .label {
      font-size: 12px;
      margin: 0px !important;
      height: 32px;
      display: inline-flex;
      align-items: center;
    }


    .input-box {
      position: relative;

      .icon {
        position: absolute;
        top: 35px;
        left: 400px;
        font-size: 20px;
      }

      .calendar-icon {
        background: white url('/images/calendar-grey.svg') right no-repeat;
        padding-right: 17px;
        left: 10px;
        width: 30px;
        background-origin: content-box;
      }

      .box {
        box-sizing: border-box;
        height: 32px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        background-color: #FFFFFF;
        margin-top: 0px;
        text-indent: 10px;
        margin-bottom: 20px !important;
        box-shadow: none !important;
      }

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          margin-left: -10px;
          margin-right: 10px;
          //margin-top: 5px;

          .box text-box {
            box-sizing: border-box;
            height: 50px;
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            //background-color: #FFFFFF;
            box-shadow: none !important;
            background: white url('/images/calendar-grey.svg') right no-repeat;
            padding-right: 17px;
            left: 10px;
            width: 30px;
            background-origin: content-box;
          }
        }
      }


    }


  }


}
