.staff-type-header {
  color: #35CC62;
  font-size: 16px;
  font-weight: bold;
}

.staff-type-content {
  color: #27395A;
  font-size: 14px;
}
