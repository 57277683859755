.file-component {
  margin-bottom: 10px;
  height: 110px;
  border-radius: 5px;
  background-color: #f4f4f4;
}

.file-icon {
  margin-top: 22px;
  height: 16px;
  width: 16px;
}

.document-name {
  color: #3d4a5e;
  font-family: "Montserrat Medium";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
  height: 20px;
  margin-bottom: 0px;
}

.document-type {
  color: #9e9e9e;
  font-family: "Montserrat Medium";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  height: 20px;
}

.document-options {
  height: 20px;
  width: 20px;
}

.add-document {
  display: table;
  margin-bottom: 10px;
  height: 110px;
  box-sizing: border-box;
  border: 2px dashed #d6d6d6;
  border-radius: 6px;
}

.loader-file {
  height: 105px;
  width: auto;
}

.add-button {
  display: table-cell;
  vertical-align: middle;
  color: #bdbdbd;
  font-family: "Montserrat SemiBold";
  font-size: 15px;
  letter-spacing: 0;
  text-align: center;
}

.box {
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  margin: 2em auto;
  padding: 1em;
  position: relative;
  width: 33%;
}

/* lil box*/

.card__corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 2em;
  height: 2em;
  background-color: #d0d0d0;
}

.card__corner .card__corner-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 2em 2em 0;
  border-color: transparent #ffffff transparent transparent;
}

#fileUpload {
  display: none;
}

.row {
  margin-bottom: 0px;
}
