
.box {
  box-shadow: none !important;
}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    margin-left: -10px;
    margin-right: 10px;
    margin-top: 5px;

    .box text-box {
      box-sizing: border-box;
      height: 50px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      //background-color: #FFFFFF;
      box-shadow: none !important;
      background: white url('/images/calendar-grey.svg') right no-repeat;
      padding-right: 17px;
      left: 10px;
      width: 30px;
      background-origin: content-box;
    }
  }
}

.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 20px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -7px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 7px;
  border-top-color: #ccc;
}