.modal {
  display: block !important;
  overflow-y: auto;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    border-radius: 7px;
    background-color: #FFFFFF;
  }

  .modal-title {
    color: #333333;
    font-size: 21px;
    line-height: 32px;
  }

}

