.login {
  width: 100%;
  min-height: 1000px;
  clear: both;
  background-color: #fafbfc;

  .title {
    height: 46px;
    color: #333333;
    font-size: 38px;
    font-weight: 600;
    line-height: 46px;
    margin-top: 90px;
  }

  .form-container {
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.07);
    margin-top: 31px;
    padding-top: 10px;

    .form {
      padding: 3vw;

      .text-box {
        margin-top: 5px;
      }
      .remember-me {
        padding-left: 30px;

        .remember-me-checkbox {
          box-sizing: border-box;
          border: 2px solid #35cc62;
          border-radius: 4px;
          cursor: pointer;
        }

        .remember-me-text {
          height: 16px;
          color: #27395a;
          font-size: 13px;
          line-height: 16px;
          margin-left: 10px;
        }
      }

      .forgot-password {
        height: 26px;
        color: #35cc62;
        font-size: 13px;
        font-weight: 600;
        line-height: 26px;
        padding-right: 30px;
      }

      .forgot-password span :hover {
        cursor: pointer;
      }
    }
  }

  .error-message {
    color: #ff584e;
    font-size: 12px;
    letter-spacing: 0.07px;
    margin-left: 2px;
  }
}

.main-nav {
  box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.25) !important;
  z-index: 99;
  height: 66px;
}

.title {
  // height: 43px;
  font-family: Muli;
  padding: 20px;
  color: #35cc62;
  font-size: 34px;
  font-weight: 900;
  line-height: 27px;
}
