.tabs {
  border-radius: 6px;
  background-color: #ffffff;
  color: #b0b9c9;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 0 10px 5px rgba(179, 179, 179, 0.09);
  padding: 15px;
  margin-right: 3px;

  &.active {
    color: #35cc62;
  }
}

.grey-area {
  border-radius: 4px;
  background-color: #fafbfc;
  padding: 30px;
  margin-bottom: 20px;
}

.title {
  color: #333333;
  font-size: 16px;
  margin-bottom: 5px;
}

.description {
  color: #5b6576;
  font-size: 14px;
  margin-bottom: 5px;
}

.progress {
  padding: 10px;
  margin-left: 18px;
  text-align: left;
}

.actionButton {
  border-radius: 4px;
  //background-color: #35CC62;
  //opacity: 0.08;\
  margin: 5px;
}

.actionButton:after {
  opacity: 0.08;
}

.actionText {
  color: #35cc62;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 14px;
}

.buttonOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
